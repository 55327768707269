import React from 'react'

const Products = () => {
    return (
        <>
            <div className='my-products'>
                <p className="products-header">Products</p>
              <hr></hr>
                <div className='grid2'>            
                    <div className='card grid-tem-product'>                       
                        <div className="product-description">  
                             <h4 className='card-header'>User Identity System</h4>
                            <p className='card-body'>
                                User Identity System contains Frontend & Backend.
                                Frontend is developed in React.
                                Backend is Web API, it is developed in ASP.NET Core.<br />
                                Please click on&nbsp;  
                                 <a target='_blank' href='https://identity.devdeskweb.net'>https://identity.devdeskweb.net</a>&nbsp;
                                to check User Identity System.
                            </p>
                        </div>
                    </div>
                    <div className='card grid-tem-product'>                       
                        <div className="product-description">  
                             <h4 className='card-header'>User Identity System Description</h4>
                            <p className='card-body'>
                                It's a Frontend application of User Identity System, developed in React.
                                Web API is the Backend of the User Identity System, developed in ASP.NET Core.
                                Frontend & Backend of the Identity System is developed based on ASP.NET Core Identity concepts.                            
                            </p>
                        </div>
                    </div>

                {/* 
                    <div className='grid-tem'>
                        <div className="">
                            <h4>2. Product 2</h4>
                            <p>
                                We developed User Identity application. It works in Frontend & Backend style approach. Frontend is developed in React and
                                Backend wEB apI is developed in ASP.NET Core. To navigate to the Identity application, please
                                <a target='_blank' href='https://identity.devdeskweb.net'> click here</a>
                            </p>
                        </div>
                    </div>
                    <div className='grid-tem'>
                        <div className="">
                            <h4>Product 2 Description</h4>
                            <p>
                                We developed User Identity application. It works in Frontend & Backend style approach. Frontend is developed in React and
                                Backend wEB apI is developed in ASP.NET Core. To navigate to the Identity application, please
                                <a target='_blank' href='https://identity.devdeskweb.net'> click here</a>
                            </p>
                        </div>
                    </div> */}

                </div>
            </div>
            <br></br>
             <br></br>
        </>
    
    )
}

export default Products